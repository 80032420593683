<template lang="html">
  <div class="ActivityReportPage">
    <div class="ActivityReportPage__Header">
      <div class="ActivityReportPage__Header__info">
        <div class="ActivityReportPage__Header__info--title">
          <img v-if="communityCurrentActivity.type=='WAT_SURVEY'" src="@/assets/img/opinionIcon.png">
          <img v-if="communityCurrentActivity.type=='EL_SURVEY'" src="@/assets/img/pollIcon.png">
          <img v-if="communityCurrentActivity.type=='WAT_TASK'" src="@/assets/img/blogIcon.png">
          <img v-if="communityCurrentActivity.type=='WAT_CHAT'" src="@/assets/img/blogIcon.png">
          <img v-if="communityCurrentActivity.type=='WAT_POLL'" src="@/assets/img/pollIcon.png">
          <img v-if="communityCurrentActivity.type=='WAT_OPINION'" src="@/assets/img/opinionIcon.png">
          <img v-if="communityCurrentActivity.type=='VIDEOCHAT'" src="@/assets/img/videoChatIcon.svg" class="videoChat-icon">

          <h1>{{communityCurrentActivity.title}}</h1>
        </div>
        <div class="ActivityReportPage__Header__info--dates">
          <span class="status-circle active"></span>
          <p>{{$t('date_from_to2',{from:formatDateForTranslation(communityCurrentActivity.dateFrom), to:formatDateForTranslation(communityCurrentActivity.dateTo)})}}</p>
        </div>
      </div>
      <div class="ActivityReportPage__Header__download">
        <button
          style="background: var(--primary-color)"
          v-on:click="fetchActivityWord()"
          v-if="isModerator"
        > {{$t('download_word')}}
        </button>
        <button
          style="background: var(--primary-color)"
          v-on:click="fetchActivityExcel()"
          v-if="isModerator"
        > {{$t('download_excel')}}
        </button>
      </div>
    </div>
    <div class="Grid">
      <div class="Grid__Content">
        <div class="Grid__Box ActivityReportPage__Progress">
          <div class="ActivityReportPage__Progress__Header">
            <p v-html="$t('members_participated_num',{participatedUsers:activityResult.participatedUsers,participants:activityResult.participants})"></p>
          </div>
          <div class="ActivityReportPage__Progress__Bar">
              <progress-bar
              :options="progressBarOptions"
              :value="progressBarOptions.value"
              />
          </div>
          <div class="ActivityReportPage__Progress__Info">
            <div class="ActivityReportPage__Progress__Info__Container ActivityReportPage__Progress__Info__Container__Next">
              <div class="ActivityReportPage__Progress__Info__Container__Content">
                <div class="ActivityReportPage__Progress__Info--Comments">
                  {{activityResult.commentsNum}}
                </div>
                <p>{{$t('comments')}}</p>
              </div>
              <div class="ActivityReportPage__Progress__Info__Container__Content" v-if="!communityInfo.hideGamification">
                <div class="ActivityReportPage__Progress__Info--Medals">
                  {{activityResult.medalsNum}}
                </div>
                <p>{{$t('medals')}}</p>
              </div>
            </div>
            <div class="ActivityReportPage__Progress__Info__Container ActivityReportPage__Progress__Info__Container__Next">
              <div class="ActivityReportPage__Progress__Info__Container__Content">
                <div class="ActivityReportPage__Progress__Info--Notes">
                  {{activityResult.notesNum}}
                </div>
                <p>{{$t('notes')}}</p>
              </div>
              <div class="ActivityReportPage__Progress__Info__Container__Content">
                <div class="ActivityReportPage__Progress__Info--Tags">
                  {{activityResult.tagsNum}}
                </div>
                <p>{{$t('tags_en')}}</p>
              </div>
            </div>
            <div class="ActivityReportPage__Progress__Info__Container">
              <div class="ActivityReportPage__Progress__Info__Container__Content">
                <div class="ActivityReportPage__Progress__Info--Likes">
                  {{activityResult.likes}}
                </div>
                <p>{{$t('likes')}}</p>
              </div>
              <div class="ActivityReportPage__Progress__Info__Container__Content">
                <div class="ActivityReportPage__Progress__Info--Dislikes">
                  {{activityResult.dislikes}}
                </div>
                <p>{{$t('dislikes')}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="Grid__Box ActivityReportPage__LastMedals" v-if="!communityInfo.hideGamification">
          <div class="Grid__Box__Header">
            <p>Comentarios nuevos</p>
          </div>
          <div class="ActivityReportPage__LastMedals__Users">
            <div v-for="comment in activityResult.unseenComments" class="comment">
              <comment
                :comment="comment"
                :allowLike="false"
                :allowDislike="false"
                :allowReply="false"
                :allowDelete="false"
                :allowTag="false"
                :allowAddMedals="false"
                :hideGamification="communityInfo.hideGamification"
                :allowCommentLink="true"
                :theme="theme">
              </comment>
            </div>
          </div>
        </div> -->
        <div class="Grid__Box ActivityReportPage__LastMedals" v-if="!communityInfo.hideGamification">
          <div class="Grid__Box__Header">
            <p>{{$t('medals_last')}}</p>
            <!--router-link :to="{ name: 'activity.members', params: { origin, id: communityCurrentActivity.identifier, } }" class="Grid__Box__Header--ALL" :style="{ color: theme.mainColor}">VER TODOS</router-link-->
          </div>
          <div class="ActivityReportPage__LastMedals__Users">
            <div v-for="comment in activityResult.lastMedals" class="comment">
              <comment
                :comment="comment"
                :allowLike="false"
                :allowDislike="false"
                :allowReply="false"
                :allowDelete="false"
                :allowTag="false"
                :allowAddMedals="false"
                :hideGamification="communityInfo.hideGamification"
                :theme="theme">
              </comment>
            </div>
          </div>
        </div>
      </div>
      <div class="Grid__Content">
        <div class="Grid__Box ActivityReportPage__Answers" v-if="activityResult.type=='EL_SURVEY' || activityResult.type=='WAT_POLL'">
          <div class="Grid__Box__Header">
            <p>{{$t('results')}} <small><span :style="{ color: theme.mainColor}">({{activityResult.answerReports.length}} {{$t('questions')}})</span></small></p>
          </div>
          <div class="ActivityReportPage__Answers__List">
            <div v-for="(answer, index) in activityResult.answerReports" class="answer">
              <div class="ActivityReportPage__Answers__List__Answer">
                  <div class="ActivityReportPage__Answers__List__Answer__Counter">
                     <span :style="{ color: theme.mainColor}">{{$t('answers_of_total',{answersNum:answer.answeredMembers, total:answer.totalMembers})}}</span>
                  </div>
                  <div class="ActivityReportPage__Answers__List__Answer__Title">
                    <span :style="{ color: theme.mainColor}">{{index+1}}.</span> {{answer.question.questionText}}
                  </div>
                  <div v-if="answer.question.type == 'TEXT'">
                    {{$t('report_text_question_answers_alert')}}
                  </div>
                  <div class="ActivityReportPage__Answers__List__Answer__Option__List" v-if="answer.question.type =='SINGLE_SELECT' || answer.question.type=='MULTI_SELECT'">
                    <div v-for="(value, key, index) in answer.resultSummatory" class="ActivityReportPage__Answers__List__Answer__Options">
                        {{key}} - {{value == 0 && answer.answeredMembers == 0 ? 0 : Math.round(((value/answer.answeredMembers)*100 + Number.EPSILON)*100) / 100}}%
                        <div class="answer__data__Progress__Bar" style="height:10px">
                          <progress-bar
                          :options="progressBarOptions"
                          :value="value == 0 && answer.answeredMembers == 0 ? 0 : (value/answer.answeredMembers)*100"
                          />
                        </div>
                    </div>
                  </div>
                  <div class="ActivityReportPage__Answers__List__Answer__Option__List" v-if="answer.question.type =='DIVIDE_POINTS'">
                    <div v-for="(value, key, index) in answer.pointsResult" class="ActivityReportPage__Answers__List__Answer__Options">
                        {{key}}-{{value.name}} ({{value.average}} {{$t('average')}})
                        <div class="answer__data__Progress__Bar" style="height:10px">
                        <progress-bar
                        :options="progressBarOptions"
                        :value="value.total == 0 && answer.answeredMembers == 0 ? 0 : (value.average/answer.question.pointsNum)*100"
                        />
                        </div>
                    </div>
                  </div>
                  <div v-if="answer.question.type =='RANKING'">
                    <p v-html="$t('answers_average',{average:answer.resultAverage, total:answer.question.maxValue})"></p>
                    <div class="answer__data__Progress__Bar" style="height:10px">
                      <progress-bar
                       :options="progressBarOptions"
                       :value="((answer.resultAverage)/answer.question.maxValue)*100"
                       />
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Grid__Box ActivityReportPage__LastComments">
          <div class="Grid__Box__Header">
            <p>{{$t('comments_last')}}</p>
            <router-link :to="{ name: 'activity.info', params: { origin, id: communityCurrentActivity.identifier, } }" class="Grid__Box__Header--ALL" :style="{ color: theme.mainColor}">{{$t('show_all')}}</router-link>
          </div>
          <div class="ActivityReportPage__LastComments__Comments">
            <div v-for="comment in activityResult.lastComments" class="comment">
              <comment
                :comment="comment"
                :allowLike="false"
                :allowDislike="false"
                :allowReply="false"
                :allowDelete="false"
                :allowTag="false"
                :allowAddMedals="false"
                :hideGamification="communityInfo.hideGamification"
                :theme="theme">
              </comment>
            </div>
          </div>
        </div>
        <div class="Grid__Box ActivityReportPage__VideoChat" v-if="communityCurrentActivity.type == 'VIDEOCHAT'">
          <div class="Grid__Box__Header">
            <p>{{$t('activity_videochat_record')}}</p>
          </div>
          <div class="ActivityReportPage__VideoChat--Video">
            <video controls="" :key="i" v-for="(src,i) in videoUrl" :controlsList="!communityInfo.allowVideosDownload?'nodownload':''">
              <source :src="src.url" type="video/mp4">
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import store from '@/store';
import {
  COMMUNITY_FETCH_ACTIVITY_RESULTS,
  COMMUNITY_FETCH_ACTIVITY_EXCEL,
  COMMUNITY_FETCH_ACTIVITY_WORD
} from '@/store/community/activities.module';

import {
  COMMUNITY_FETCH_VIDEO_RECORD,
 } from '@/store/community/chat.module';

import Comment from '~/components/Comment';
import ProgressBar from '@/components/ProgressBar.vue';
import Avatar from '~/components/Avatar';

import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import { map, flatMap } from 'lodash';
import FileSaver from 'file-saver';
import numeral from 'numeral';

export default {
  name: 'ActivityReportPage',

  components:{
    'progress-bar': ProgressBar,
    Avatar,
    Comment,
  },

  computed: {
    ...mapGetters([
      'theme',
      'communityActivities',
      'communityCurrentActivity',
      'communityCurrentActivityId',
      'isClient',
      'isModerator',
      'isUser',
      'origin',
      'communityInfo',
    ]),
  },
  data(){
    return{
        activityResult:{},
        progressBarOptions:{
         value:0,
         background:"",
       },
       videoUrl:[],
    }
  },

  async mounted(){
    this.fetchActivityResult()
  },

  methods:{
    fetchActivityResult: async function(){
      await store.dispatch(COMMUNITY_FETCH_ACTIVITY_RESULTS,{activityId:this.$route.params.id})
      .then((data) => {
        this.activityResult = data
        this.progressBarOptions.value = (100/data.participants) * data.participatedUsers
        this.progressBarOptions.background = "linear-gradient(to right,"+this.theme.mainColor+", #b0040a)"
        this.activityResult.lastMedals = _.filter(this.activityResult.lastMedals, function (o, i) {
          o.image = o.picture
          o.creationDate = o.commentDate
          o.nickname = o.nickName
          o.testerPoints = o.points
          return o
        });
        this.activityResult.lastComments = _.filter(this.activityResult.lastComments, function (o, i) {
          o.image = o.picture
          o.creationDate = o.commentDate
          o.nickname = o.nickName
          o.testerPoints = o.points
          return o
        });
      })
      await store.dispatch(COMMUNITY_FETCH_VIDEO_RECORD,{id:this.$route.params.id})
      .then((data) => {
        this.videoUrl = data
      });
    },

    fetchActivityExcel: async function(){
      var vm = this;
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        text: vm.$t('msg_file_download'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_accept'),
        showCancelButton: true,
        customClass:{
          popup:'swal-customWarning'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            await store.dispatch(COMMUNITY_FETCH_ACTIVITY_EXCEL,{activityId:vm.communityCurrentActivityId})
            .then((data) => {
              var file = new Blob([data],  {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
              FileSaver.saveAs(file,vm.$t('activity')+" "+vm.communityCurrentActivityId+".xlsx");
            })
          })();
        }
      })
    },
    fetchActivityWord: async function(){
      var vm = this;
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        text: vm.$t('msg_file_download'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_accept'),
        showCancelButton: true,
        customClass:{
          popup:'swal-customWarning'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            await store.dispatch(COMMUNITY_FETCH_ACTIVITY_WORD,{activityId:vm.communityCurrentActivityId})
            .then((data) => {
              var file = new Blob([data],  {type:'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
              FileSaver.saveAs(file,vm.$t('activity')+" "+vm.communityCurrentActivityId+".docx");
            })
          })();
        }
      })
    },
    formatDateForTranslation(date){
      if (date == undefined) {
        return "-"
      }
      return moment(date).format("DD/MM HH:mm");
    },
  },
}
</script>

<style scoped lang="scss">
.ActivityReportPage{
  width: 100%;
  background: #f9f9f9;
  max-height: calc(100vh - 100px);
  margin-top:-37px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-track{
    background-color: #e6e6e6;
  }
  &::-webkit-scrollbar {
    background-color: #8134ff;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: var(--primary-color);
  }
  &__Header{
    background: white;
    padding: 20px;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &__info{
      display: flex;
      flex-direction: column;
      .videoChat-icon{
        width: 36px;
        padding: 5px;
      }
      &--title{
        display: flex;
        align-items: center;
        img{
          width: 40px;
        }
        h1{
          font-size: 33px;
          font-weight: 500;
        }
      }
      &--dates{
        display: flex;
        align-items: baseline;
        color: #959494;
        font-size: .8rem;
        .status-circle{
          margin: 0 15px!important;
        }
      }
    }
    &__download{
      button{
        border: none;
        font-size: 0.9em;
        padding: 10px 20px;
        margin-left: 5px;
        color: white;
        text-transform: uppercase;
        cursor: pointer;
        outline: none;
      }
    }
  }
  &__Progress{
    &__Header{
      p{
        font-size: 20px;
        span{
          font-weight: bold;
          font-size: 24px;
        }
      }
    }
    &__Bar{
      margin: 10px 0;
      height: 15px;
    }
    &__Info{
      display: flex;
      flex-direction: column;
      &__Container{
        display: flex;
        font-size: 40px;
        color: #777777;
        text-transform: uppercase;
        &__Content{
          display: flex;
          align-items: center;
          width: 100%;
          div{
            font-weight: 600;
            width:70px;
            text-align:right;
          }
          p{
            padding-left: 10px;
            font-size: 14px;
          }
        }
        &__Next{
          border-bottom: 1px solid #e9e9e9;
        }
      }
      &--Comments{
        color: #77acd5;
      }
      &--Medals{
        color: #e6bc2b;
      }
      &--Notes{
        color: #de8033;
      }
      &--Tags{
        color: #a04cbb;
      }
      &--Likes{
        color: #94ce77;
      }
      &--Dislikes{
        color: #e84040;
      }
    }
  }
  &__Answers__List{
    font-size: 0.9em;
    &__Answer{
      margin-top:10px;
      border-top: 1px solid #e9e9e9;
      padding-top: 15px;
      padding-bottom: 15px;
      &__Title{
        font-weight: 600;
        margin-bottom: 10px;
      }
      &__Option__List{
        margin-left: 20px;
      }
      &__Options{
        font-size: 0.95em;
        margin-bottom: 5px;
      }
      &__Counter{
        float: right;
      }
    }
  }
  &__LastMedals{
    &__Medals{
      div{
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
        border-bottom: 1px solid #e9e9e9;
        align-items: center;
        padding: 5px 0;
        img{
          height: 30px;
        }
        p{
          color: #c4c4c4;
          font-size:24px;
          font-weight: 600;
        }
      }
    }
  }
  &__VideoChat{
    &--Video{
      width:100%;
      margin-top:10px;
      video{
        width:100%;
      }
    }
  }
  .Grid{
    display: flex;

    @media screen and ( max-width: 768px ) {
      flex-direction: column;
    }

    &__Content{
      width: 100%;
    }
    &__Box{
      padding:20px;
      margin: 10px;
      box-shadow: 1px 2px 5px #e6e6e6;
      background: white;
      &__Header{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        p{
            font-size:18px;
            color: black;
        }
        &--ALL{
          font-size: 12px!important;
          cursor: pointer;
          text-transform: uppercase;
        }
      }
    }
  }
  .comment{
    display:flex;
    border-bottom: 1px solid #e9e9e9;
    padding: 15px 0;
    &__picture{
      width: 90px;
      height: 90px;
      min-width: 90px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 10px;
      border:1px solid #e9e9e9;
    }
    &__data{
      &__header{
        font-size: 17px;
        font-weight: 600;
      }
      &__dates{
        color: #888888;
        font-size:.8rem;
      }
      &__text{
        font-size: 14px;
        color: #696969;
        margin-top:10px;
      }
      &__medals{
        margin-top: 10px;
        img{
          width: 20px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.ActivityReportPage{
  .Comment {
    //padding-bottom: 0.5em;
    &__Content{
      box-shadow: none!important;
      padding: 0;
    }
    &__Note-Tags{
      width: none;
      padding: 10px 0 20px 0;
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px double #f1f1f1;
      .bubble{
        position: relative;
        padding: 0;
        margin: 0;
        border-radius: 0;
        background: white;
        color: black;
        border:none;
        &::after{
          display:none;
        }
      }
    }
    &__Header{
      display: flex;
      justify-content: space-between;
      &__UsernameContainer {
        height: 38px;
        h4{
          font-weight: 700;
          font-size: .8rem;
          margin: 0;
        }
      }
      span{
        color: #9c9b9b;
        font-size: 13.5px;
        margin: 0 .5em .5em 0;
      }
      &--medals{
        img{
          width:20px;
          opacity: 0.5;
          &:hover,&.active{
            opacity: 1;
            cursor: pointer;
          }
        }
      }
      &--listedMedals{
        margin-left: -2px;
        margin-top: 5px;
        img{
          width: 15px;
        }
      }
    }
    &__Body{
      color: #9c9b9b;
      font-size:12px;
      word-wrap: break-word;
    }
    &__Footer{
      .Reaction{
        &--Reply{
          button{
            color: var(--primary-color) !important;
            font-size: 11px;
            font-family: 'Open Sans', Helvetica, Arial, sans-serif!important;
            font-weight: 600;
            padding:0px;
            margin-right: 10px;
            cursor: pointer;

          }
        }
        &__Counter {
          font-size: .8rem;
          margin-left: -5px;
          margin-top:6px;
        }
      }
    }
  }
}
</style>
